import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { updateLayoutSettings, collapseAsideFooter, expandAsideFooter } from 'common/actions';

import About from './components/About';
import Cap from './components/Cap';
import Couples from './components/Couples';
import Footer from './components/Footer';
import Functions from './components/Functions';
import Intro from './components/Intro';
import Link from 'components/Link';
import Meta from 'components/Meta';

import { resolution } from 'common/helpers';

import './style.css';
import { Helmet } from 'react-helmet';

class Landing extends Component {
  static async getInitialProps({ store }) {
    store.dispatch(updateLayoutSettings({
      footer: false,
      recommended: false,
    }));

    return;
  }

  constructor(props) {
    super(props);
    this.footerRef = React.createRef();
    this.watchFooter = this.watchFooter.bind(this);
    this.detectResolution = this.detectResolution.bind(this);
    this.state = {
      isDesktop: false,
    };
  }

  componentDidMount() {
    this.detectResolution();
    window.addEventListener('scroll', this.watchFooter, false);
    window.addEventListener('resize', this.detectResolution, false);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.watchFooter, false);
    window.removeEventListener('resize', this.detectResolution, false);
  }

  watchFooter() {
    const { collapseAsideFooter, expandAsideFooter } = this.props;
    const { shortAsideFooter } = this.props.layoutSettings;
    const footer = this.footerRef.current;
    const footerOffset = footer.getBoundingClientRect().bottom;
    const footerHeight = footer.clientHeight;
    const shouldHideAsideFooter = footerOffset - window.innerHeight - footerHeight * 0.7 <= 0;

    if (shortAsideFooter !== shouldHideAsideFooter) {
      if (shouldHideAsideFooter) {
        collapseAsideFooter();
      } else {
        expandAsideFooter();
      }
    }
  }

  detectResolution() {
    const isDesktop = !resolution.isMobile() && !resolution.isTablet();

    if (this.state.isDesktop !== isDesktop) {
      this.setState({
        isDesktop,
      });
    }
  }

  render() {
    const { isDesktop } = this.state;

    return (
      <React.Fragment>
        <Meta
          title="Сайт знакомств LinkYou — приятные знакомства для серьезных отношений"
          description="Личные знакомства и деловые контакты на LinkYou. Поиск пары по профессии и общим интересам. На сайте более 200 профессий и тысячи реальных анкет"
          image="/images/og-image.png"
        />
        <Helmet>
          <link rel="amphtml" href="https://linkyou.ru/amp/preview" />
        </Helmet>
        <div className="landing">
          { isDesktop
            ? <div className="landing__desktop">
                <Intro />
                <Couples />
                <About />
                <Functions />
                <Footer ref={ this.footerRef } />
              </div>
            : <div className="landing__mobile">
                <Cap />
              </div>
          }
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    layoutSettings: state.layoutSettings,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return bindActionCreators({
    collapseAsideFooter,
    expandAsideFooter,
  }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Landing);

import React from 'react';

import FormButton from 'components/FormButton';
import Share from 'components/Share';

import './style.css';
import introCoupleImage from './images/couple.svg';
import introHcsochiImage from './images/hcsochi.svg';
import introRestrictionImage from './images/restriction.svg';

const PRESS = [
  { image: 'izvestia', link: 'http://izvestia.ru/news/646865' },
  { image: 'rt', link: 'https://russian.rt.com/russia/news/334826-rossiya-analog-linkedin' },
  { image: 'ria', link: 'https://ria.ru/society/20161123/1481953275.html' },
  { image: '360', link: 'http://360tv.ru/news/u-zablokirovannogo-linkedin-poyavilsya-rossijskij-analog-80959/' },
  { image: 'life', link: 'https://life.ru/t/%D1%82%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D0%B8/935670/v_rossii_uzhie_sozdali_svoi_linkedin' },
  { image: 'ren', link: 'http://ren.tv/novosti/2016-11-23/u-socialnoy-seti-linkedin-poyavilsya-rossiyskiy-analog' },
  { image: 'businessfm', link: 'http://businessfm.spb.ru/novosti/pochti-takojj-zhe-no-otechestvennyjj' },
];

// Fuck manual import
const PRESS_IMAGES = PRESS.slice(0).reduce((result, article) => {
  result[article.image] = Array(2).fill(0).map((v, i) =>
    require(`./images/press/${article.image}@${i + 1}x.png`)
  );
  return result;
}, {});

const LandingIntro = () => (
  <div className="landing-intro">
    <div className="landing-intro__content">
      <div className="landing-intro__content-wrapper">
        <div className="landing-intro__body">
          <div className="landing-intro__body-headline">
            <h1>Сервис знакомств для людей<br className="hidden-b-d" /> с интеллектом</h1>
            <p>LinkYou&nbsp;&mdash; это закрытый клуб знакомств, где&nbsp;Вы можете найти равного себе партнера для жизни</p>
            <FormButton skin={ ['green', 'size-m'] } to="/welcome">
              Гостевой вход
            </FormButton>
          </div>
          <div className="landing-intro__body-image">
            <img src={ introCoupleImage } alt="" />
          </div>
        </div>
        <div className="landing-intro__share">
          <Share skin="landing" />
        </div>
        <div className="landing-intro__press">
          <div className="landing-intro__press-title">СМИ о нас:</div>
          {
            PRESS.map(article =>
              <a
                href={ article.link }
                target="_blank" rel="noopener noreferrer"
                className="landing-intro__press-link"
                key={ article.image }
              >
                <img
                  src={ PRESS_IMAGES[article.image][0] }
                  srcSet={ PRESS_IMAGES[article.image].map((img, i) => `${img} ${i + 1}x`) }
                  alt=""
                />
              </a>
            )
          }
        </div>
        <div className="landing-intro__restriction">
          <img src={ introRestrictionImage } alt="18+" />
        </div>
        <div className="landing-intro__hcsochi">
          <a href="http://www.hcsochi.ru/" target="_blank" rel="noopener noreferrer">
            <img src={ introHcsochiImage } alt="ХК «Сочи»" />
          </a>
        </div>
      </div>
    </div>
  </div>
);

export default LandingIntro;

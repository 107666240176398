import React from 'react';

import './style.css';
import laptopImage from './images/laptop.svg';
import laptopScreenImage from './images/laptop-screen.jpg';
import laptopScreenImageRetina from './images/laptop-screen@2x.jpg';
import phoneImage from './images/phone.svg';
import phoneScreenImage from './images/phone-screen.jpg';
import phoneScreenImageRetina from './images/phone-screen@2x.jpg';

const LandingFunctions = () => (
  <div className="landing-functions">
    <div className="landing-functions__text">
      <h2>Много полезных функций и приятных сюрпризов</h2>
      <p>Команда LinkYou постоянно работает над улучшением сервиса, стараясь сделать его максимально удобным и эффективным.</p>
    </div>
    <div className="landing-functions__preview">
      <div className="landing-functions__preview-laptop">
        <img
          className="landing-functions__preview-laptop-body"
          src={ laptopImage }
          width="1181"
          height="681"
          alt=""
        />
        <img
          className="landing-functions__preview-laptop-screen"
          src={ laptopScreenImage }
          srcSet={ `${laptopScreenImage} 1x, ${laptopScreenImageRetina} 2x` }
          width="890"
          height="571"
          alt=""
        />
      </div>
      <div className="landing-functions__preview-phone">
        <img
          className="landing-functions__preview-phone-body"
          src={ phoneImage }
          width="188"
          height="369"
          alt=""
        />
        <img
          className="landing-functions__preview-phone-screen"
          src={ phoneScreenImage }
          srcSet={ `${phoneScreenImage} 1x, ${phoneScreenImageRetina} 2x` }
          width="155"
          height="271"
          alt=""
        />
      </div>
    </div>
  </div>
);

export default LandingFunctions;

import React from 'react';

import './style.css';

const DATA = [
  {
    image: require('./images/couple.svg'),
    headline: 'Поиск по профессии поможет найти людей, с которыми у вас много общего',
    text: 'Более двухсот профессий на сайте LinkYou. Найти свою половину по професии теперь стало возможным.',
  },
  {
    image: require('./images/robot.svg'),
    headline: 'Никаких роботов – только живые люди на сайте',
    text: 'Мы за честность. На LinkYou нет ни одной поддельной анкеты.',
  },
  {
    image: require('./images/rude.svg'),
    headline: 'LinkYou против хамства и предложений интима',
    text: 'Нашли на LinkYou скрытую рекламу интим-услуг или вас оскорбляет другой участник сервиса? Просто сообщите об этом в службу технической поддержки.',
  },
  {
    image: require('./images/discord.svg'),
    headline: 'Поиск по национальности и вероисповеданию',
    text: 'На LinkYou можно искать людей своей национальности и вероисповедания. Мы уважаем все религии и народы.',
  },
];

const LandingAbout = () => (
  <div className="landing-about">
    {
      DATA.map((benefit, key) =>
        <div className="landing-about__benefit" key={ key }>
          <div className="landing-about__benefit-icon">
            <img src={ benefit.image } alt="" />
          </div>
          <div className="landing-about__benefit-desc">
            <div className="landing-about__benefit-headline">
              { benefit.headline }
            </div>
            <div className="landing-about__benefit-text">
              { benefit.text }
            </div>
          </div>
        </div>
      )
    }
  </div>
);

export default LandingAbout;

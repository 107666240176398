import React, { Component } from 'react';
import classNames from 'classnames';

import './style.css';

const COUPLES = {
  pilot: {
    male: 'Познакомлюсь с Жанной. Стюардессой.',
    female: 'Ищу не знающего себе равных пилота.',
  },
  architector: {
    male: 'Архитектор перестроит шалаш в шале для райских отношений с любимой — дизайнером интерьеров.',
    female: 'Нежная девушка, дизайнер интерьеров, ищет своего Гауди.',
  },
  doctor: {
    male: 'Стоматолог-виртуоз познакомится с Риной. Врачом.',
    female: 'Независимая женщина, хирург–ринопластик, составит счастье свободному стоматологу.',
  },
  developer: {
    male: 'Девелопер познакомиться с эффектной/эффективной менеджером рынка недвижимости.',
    female: 'Энергичная женщина, риелтор, выстроит высокие отношения с предпринимателем в области малоэтажного строительства.',
  },
  programmer: {
    male: 'Программист ищет девушку не из Силиконовой долины.',
    female: 'Девушка, web–дизайнер с дерзким интерфейсом, ищет своего IT-гения.',
  },
  cook: {
    male: 'Изумительно готовящий шеф–повар ищет приятную коллегу.',
    female: 'Шеф–кондитер с изюминкой познакомиться с шеф–поваром для серьезных отношений',
  },
  business: {
    male: 'Состоятельный акционер ищет успешную женщину. Женюсь.',
    female: 'Дама, вице-президент компании, ищет паритетных взаимоотношений с успешным бизнесменом.',
  },
};

const COUPLES_TYPES = Object.keys(COUPLES);

// Fuck manual import
const COUPLES_IMAGES = Object.keys(COUPLES).reduce((result, couple) => {
  result[couple] = Object.keys(COUPLES[couple]).reduce((images, gender) => {
    images[gender] = require(`./images/${couple}-${gender}.svg`);
    return images;
  }, {});
  return result;
}, {});

let autoplayTimer;

class LandingCouples extends Component {
  constructor(props) {
    super(props);
    this.handleNav = this.handleNav.bind(this);
    this.autoplay = this.autoplay.bind(this);
    this.startAutoplay = this.startAutoplay.bind(this);
    this.state = {
      currentCouple: 'pilot',
    };
  }

  componentDidMount() {
    this.startAutoplay();
  }

  componentWillUnmount() {
    clearInterval(autoplayTimer);
  }

  autoplay() {
    const { currentCouple } = this.state;
    const prevIndex = COUPLES_TYPES.indexOf(currentCouple);
    let nextIndex = prevIndex + 1;

    if (nextIndex >= COUPLES_TYPES.length) {
      nextIndex = 0;
    }

    this.changeSlide(COUPLES_TYPES[nextIndex]);
  }

  startAutoplay() {
    clearInterval(autoplayTimer);
    autoplayTimer = setInterval(this.autoplay, 5000);
  }

  changeSlide(coupleType) {
    if (!coupleType) {
      return;
    }

    this.setState({
      currentCouple: coupleType,
    }, this.startAutoplay);
  }

  handleNav(e) {
    const nextCouple = e.currentTarget.getAttribute('data-couple');

    this.changeSlide(nextCouple);
  }

  render() {
    const { currentCouple } = this.state;

    return (
      <div className="landing-couples">
        <div className="landing-couples__about">
          <div className="landing-couples__about-text">
            <h2>LinkYou помогает создавать крепкие пары</h2>
            <p>Люди общих и похожих профессий всегда найдут темы для общения. Они быстрее сходятся, ближе мыслят, поддерживают друг друга, и создают прочные союзы.</p>
          </div>
        </div>
        <div className="landing-couples__slider">
          <div className="landing-couples__slider-content">
            {
              COUPLES_TYPES.map(couple =>
                <div
                  className={classNames(
                    'landing-couples__couple',
                    { '-active': currentCouple === couple },
                  )}
                  key={ couple }
                >
                  {
                    Object.keys(COUPLES[couple]).map(gender =>
                      <div className={ `landing-couples__couple-person -${gender}` } key={ couple + gender }>
                        <div className="landing-couples__couple-person-avatar">
                          <img src={ COUPLES_IMAGES[couple][gender] } alt="" />
                          { gender === 'female'
                            ? <div className="landing-couples__couple-person-online" />
                            : null
                          }
                        </div>
                        <div className="landing-couples__couple-person-copy">
                          { COUPLES[couple][gender] }
                        </div>
                      </div>
                    )
                  }
                </div>
              )
            }
          </div>
          <div className="landing-couples__slider-nav">
            {
              Array(COUPLES_TYPES.length).fill(0).map((d, i) =>
                <button
                  type="button"
                  className={classNames(
                    'landing-couples__slider-nav-dot',
                    { '-active': i === COUPLES_TYPES.indexOf(currentCouple) },
                  )}
                  data-couple={ COUPLES_TYPES[i] }
                  onClick={ this.handleNav }
                  key={ i }
                />
              )
            }
          </div>
        </div>
      </div>
    );
  }
}

export default LandingCouples;

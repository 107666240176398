import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { openModalCooperation } from 'common/actions';

import Link from 'components/Link';
import Share from 'components/Share';

import './style.css';

const LandingFooter = React.forwardRef(({openModalCooperation}, ref) => (
  <div className="landing-footer" ref={ref}>
    <div className="landing-footer__share">
      <div className="landing-footer__share-title">
        Расскажите друзьям о LinkYou:
      </div>
      <Share skin="landing" />
    </div>
    <div className="landing-footer__menu">
      <div className="landing-footer__menu-link">
        <a href="https://www.admitad.ru/store/offers/linkyou/" target="_blank">
          Партнерская программа
        </a>
      </div>
      <div className="landing-footer__menu-link">
        <Link to="/rules">Правила</Link>
      </div>
      <div className="landing-footer__menu-link">
        <Link to="/privacy">Конфиденциальность</Link>
      </div>
      <div className="landing-footer__menu-link">
        <button type="button" onClick={openModalCooperation}>
          Сотрудничество
        </button>
      </div>
    </div>
  </div>
));

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    openModalCooperation,
  }, dispatch);
}

export default connect(
  null,
  mapDispatchToProps,
  null,
  { forwardRef: true },
)(LandingFooter);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Link from "components/Link";
import { openModalAuth } from 'common/actions';
import { VIEW_SIGNIN, VIEW_SIGNUP } from 'components/Auth';

import coupleImage from './images/couple.svg';
import logoImage from './images/logo.svg';
import './style.css';

class LandingCap extends Component {
  constructor(props) {
    super(props);
    this.openModal = this.openModal.bind(this);
  }

  openModal(e) {
    this.props.openModalAuth(e.currentTarget.getAttribute('data-modal'));
  }

  openModal(e) {
    this.props.openModalAuth(e.currentTarget.getAttribute('data-modal'));
  }

  render() {
    const { isAMP } = this.props;
    return (
      <div className="landing-cap">
        <div className="landing-cap__restriction">18+</div>
        <div className="landing-cap__wrapper">
          <div className="landing-cap__logo">
            <div className="landing-cap__logo-icon">
              {!isAMP && <img src={logoImage} alt="LinkYou" />}
              {isAMP && (
                <amp-img
                  height="37"
                  width="230"
                  layout="fill"
                  src={logoImage}
                  alt="LinkYou"
                />
              )}
            </div>
            <div className="landing-cap__logo-slogan">
              Знакомьтесь <br /> по профессии
            </div>
          </div>
          <div className="landing-cap__image">
            {!isAMP && <img src={coupleImage} alt="" />}
            {isAMP && (
              <amp-img
                height="37"
                width="230"
                layout="fill"
                src={coupleImage}
                alt=""
              />
            )}
          </div>
          <div className="landing-cap__content">
            <div className="landing-cap__about">
              LinkYou – это закрытый клуб знакомств, где Вы можете найти равного
              себе партнера для жизни
            </div>
            <div className="landing-cap__buttons">
              {!isAMP ? (
                <>
                  <button
                    type="button"
                    className="landing-cap__button -signup"
                    onClick={this.openModal}
                    data-modal={VIEW_SIGNUP}
                  >
                    Регистрация
                  </button>
                  <button
                    type="button"
                    className="landing-cap__button -signin"
                    onClick={this.openModal}
                    data-modal={VIEW_SIGNIN}
                  >
                    Войти
                  </button>
                  <Link className="landing-cap__button -guest" to="/welcome">
                    Гостевой вход
                  </Link>
                </>
              ) : (
                <>
                  <a
                    href="https://linkyou.ru/welcome"
                    className="landing-cap__button -signup"
                  >
                    Регистрация
                  </a>
                  <a
                    href="https://linkyou.ru/welcome"
                    className="landing-cap__button -signin"
                  >
                    Войти
                  </a>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    openModalAuth,
  }, dispatch);
}

export default connect(
  null,
  mapDispatchToProps,
)(LandingCap);
